.elx-shell,
body {
  margin: unset !important;
}
.test {
  height: 100px;
}
.head {
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
}
.headerStyle {
  font-weight: 600;
  font-size: 8px;
}
.service-hub .elx-sidebar-nav {
  background-color: white;
}
.ms-GroupedList {
  max-width: unset;
}
.ms-DocumentCardTitle {
  padding: 5px 0px;
  font-weight: 600;
}
/* .root-172 {
    border: 1px solid #8d1212cc ;
    float: right;
    margin-right: 10px;
} */
.ms-DocumentCardDetails {
  margin: 10px 15px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  padding: 20px;
  width: auto;
  /* overflow-x: scroll; */
  /* overflow: initial; */
}
.ms-DocumentCard {
  margin-left: 10px;
  padding: 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  margin: 20px 0px;
  user-select: text;
}
.ms-Stack {
  font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 13px;
}
.ms-SearchBox {
  width: 50%;
}
.ms-Button:hover {
  background-color: #e5e2e2;
}
.ms-Label {
  padding: 10px;
}
.headingContainer-159 {
  padding: 0px 1.2rem;
}
.__json-pretty__ {
  background: #dcdcdc;
}
.card-styles {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 20px;
  padding: 10px;
  float: right;
  max-width: 1000px;
  width: 1000px;
  height: auto;
}
.sub-card-styles {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
  padding: 20px 20px;
  /* max-width:970px;
    width:auto; */
  max-width: 100%;
  height: auto;
  margin: 15px 10px;
}
.sub-card-styles-packages {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
  padding: 20px 20px;
  /* max-width:970px;
    width:auto; */
  max-width: 100%;
  height: auto;
  margin: 0px 10px;
}
.container-body {
  padding: 0px;
}
.sub-card-styles-services {
  padding: 10px;
}
.block {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px black solid;
  text-align: center;
  color: white;
  background-color: gray;
}
table {
  font-size: 13px;
  color: #666;
  padding-top: 20px;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  margin-left: 10px;
}
.table-design {
  border-bottom: 2px solid #e6e0e0;
  box-shadow: #e6e0e0 0px 2px 5px;
}
th {
  text-align: left;
  padding: 10px;
}
td {
  border: 0.5px solid #e5e2e2;
  text-align: left;
  padding: 10px;
}
thead {
  font-size: 14px;
}
.row-header {
  color: black;
  /* background: #6c7ae0; */
  background-color: #e3e3e3;
}
.row-data td {
  background-color: #fff;
  color: black;
}
.contract-search-box {
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: white;
  margin: 0px;
}
.contract-search-box input {
  width: 30%;
  padding: 10px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: 0.2px solid #8d1212cc;
  margin-left: 20px;
  margin-right: 10px;
  float: right;
  border-radius: 20px;
  outline: none;
}

.contract-search-box button {
  margin-left: 5px;
  width: 80px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  background-color: #8d1212cc;
  color: white;
  border: none;
  float: right;
  border-radius: 20px;
}
button:focus {
  outline: none;
}
.elx-container {
  display: contents;
  user-select: text;
}

.elx-container .container-body {
  display: initial;
  user-select: none;
}
.contract-modal {
  margin: 0px 20px;
  padding: 0px 10px;
  font-size: 14px;
}
.default-contract {
  text-align: center;
  margin: 20px 20px;
  padding: 150px;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  font-size: 14px;
}
.default-email-verification-card {
  text-align: center;
  margin: 20px 20px;
  padding: 50px;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  font-size: 10px;
}
.__json-pretty__ {
  padding: 10px;
  width: 700px;
  user-select: text;
}
.table.table-sm th,
table.table-sm td {
  padding-left: 0.6rem;
  background-color: white;
}
.table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled,
.table-table.dataTable .table-foot {
  background-color: #e3e3e3;
  font-size: 14px;
  font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-weight: 400;
}
tbody + thead {
  display: none;
}
.heading2 {
  padding: 20px 20px 30px 20px;
  font-size: 25px;
  font-weight: 700;
  border-bottom: 2px solid #8d1212cc;
  font-family: Candara;
}
.button:focus {
  outline: none;
}
.overview-contract-panel-button {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: black;
  font-weight: 600;
  background-color: white;
  outline: none;
  border: 0.8px solid #8d1212cc;
  border-radius: 4px;
}

.contract-title {
  font-size: 18px;
  font-weight: 600;
  color: #8d1212;
}
.contract-package-title {
  font-size: 18px;
  font-weight: 600;
  color: #6c6464;
}
.scc-input-style {
  margin: 5px 10px;
  padding: 4px 8px;
  width: 300px;
  max-width: 50%;
}
.scc-submit-style {
  margin: 10px 10px;
  width: 50%;
  padding: 5px 10px;
  border: 1px solid #8d1212;
  outline: none;
  font-weight: 600;
  background-color: #8d12122e;
}
.json-button {
    margin: 10px;
}

.contract-validation-table .container-header {
  display: none;
}

.contract-validation-table .elx-table {
  margin: 0 !important;
}

.csam-alias-input-style {
  margin: 5px 10px;
  padding: 4px 8px;
  width: 300px;
  max-width: 50%;
}