.ms-Button-icon {
	padding: 0px !important;
}
.ms-Link .elx-link .elx-app-title {
	width: auto;
}
.elx-breadcrumb {
	display: none;
}
.elx-header-actions {
	margin-top: 40px;
}
.ms-Toggle-background {
	margin-right: 10px;
}
.elx-sidebar-nav {
	min-width: 12rem;
}
.container-header {
	margin-left: 0px;
}
.ms-Button:hover {
	background-color: #f3f2f114;
}
.ms-DetailsList {
	user-select: text;
}
.elx-tooltip-host {
	width: 'fit-content';
}
.ms-DetailsList-headerWrapper {
	top: -20px;
}
.elx-header-actions {
	margin-top: 0px;
}
.elx-searchbox-icon {
	color: #0078d4;
}
:root {
	--search-color: #e59216;;
	--contracts-color: #8d1212;
	--scripts-color: #7f2c8c;
	--features-color: #4aaac2;
	--settings-color: #127122;
	--wiki-color: #0f409a;
	--atlas-color: #007bff;
	--audit-color: #2d1690;
}
.home-cards {
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	width: 400px;
	margin: 20px;
	height: 200px;
	overflow: hidden;
	border-top: 3px solid var(--search-color);
	position: relative;
	padding: 30px;
    font-style: italic;
}
.home-cards-contracts {
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	width: 400px;
	margin: 20px;
	height: 200px;
	border-top: 3px solid var(--contracts-color);
	position: relative;
	padding: 30px;
	font-style: italic;
}
.home-cards-scripts {
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	width: 400px;
	height: 200px;
	margin: 20px;
	overflow: hidden;
	border-top: 3px solid var(--scripts-color);
	padding: 30px;
	position: relative;
    font-style: italic;
}
.home-cards-features {
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	padding: 30px;
	width: 400px;
	margin: 20px;
	height: 200px;
	overflow: hidden;
	border-top: 3px solid var(--features-color);
	position: relative;
    font-style: italic;
}
.home-cards-settings {
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	padding: 30px;
	overflow: hidden;
	width: 400px;
	margin: 20px;
	height: 200px;
	border-top: 3px solid var(--settings-color);
	position: relative;
    font-style: italic;
}
.home-cards-kb {
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	padding: 30px;
	width: 400px;
	margin: 20px;
	height: 200px;
	overflow: hidden;
	border-top: 3px solid var(--wiki-color);
	position: relative;
    font-style: italic;
}
.home-cards-atlas {
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	padding: 30px;
	width: 400px;
	margin: 20px;
	height: 200px;
	overflow: hidden;
	border-top: 3px solid var(--atlas-color);
	position: relative;
    font-style: italic;
}
.home-cards-aceaudit {
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	padding: 30px;
	width: 400px;
	margin: 20px;
	height: 200px;
	overflow: hidden;
	border-top: 3px solid var(--audit-color);
	position: relative;
    font-style: italic;
}
.home-cards-editannouncement {
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	padding: 30px;
	width: 400px;
	margin: 20px;
	height: 200px;
	overflow: hidden;
	border-top: 3px solid #eac510;
	position: relative;
    font-style: italic;
}
.home-cards-back {
	max-width: 100%;
	height: auto;
	padding: 30px;
	box-shadow: none;
}
.heading1{
    display: flex;
    flex-direction: row;
	font-style: normal;
}
.card-heading {
	padding: 5px;
}
.card-details {
	font-size: 14px;
    
    list-style: none;
}
.card-image {
	/* float: right; */
	width: 20px;
	height: 20px;
	margin-left: 10px;
}
.card-link {
	position: absolute;
	/* padding-top: 20px; */
	font-size: 15px;
	font-style: italic;
	vertical-align: middle;
	/* color: #0078D4; */
}
.json-button {
	role: 'JSON Button';
}
.elx-branding {
	margin: 0 10px;
}
.elx-app-title {
	width: auto;
	margin: 0px 10px;
	font-weight: 600;
}
.ms-Button-label{
	font-size: 13px;
}
