.container-navigation{
  overflow: overlay;
  user-select: text;
}
.feature-button{
  background-color: #4AAAC2;
  color: white;
  padding: 4px;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
}
.edit-subheadings{
  font-size: 18px;
  color:#4AAAC2;
  font-weight: 600;
}
.feature-panel-items{
  margin: 10px 5px;
}
.feature-flights-card
{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px 20px;
    max-width:100%;
    height: auto;    
}
.ms-Button-icon{
  color:#0078D4;
}
.feature-flights-card{

  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
  padding: 0px 20px;
  max-width:100%;
  height: auto;  
  margin: 0px 10px;    
}
.ms-Panel-main{
  width:1000px;
}
.ms-FocusZone{
    width:auto;
}
/*.elx-label{
  color: black;
}*/
.label-root::before{
  color: rgb(168, 0, 0);
}
.flight-key-style{
background-color: gray
}
.elx-card{
  background: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin-top: 15px;
}
.elx-tag{
  background-color: #4aaac224;
  margin: 7px 0px;
}
.elx-table{
  user-select: 'text';
}
.ms-Panel-main{
  overflow: hidden;
}
