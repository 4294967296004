.panel-name {
    font-size: 18px;
    color: #7F2C8C;
    margin: 10px 5px;
    font-weight: 600;
}
.contract-panel-name {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 10px 5px;
    font-weight: 600;
}