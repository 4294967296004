.script-table{
  margin: 10px 0px;
  padding: 15px 40px;
  overflow: scroll;
}
.script-title{
  font-size: 17px;
  color:#7F2C8C;
  margin: 10px 10px;
}
.script-date-picker{
  font-size: 18px;
}
.panel-name{
  font-size: 18px;
  color:#7F2C8C;
  margin: 10px 5px;
  font-weight: 600;
}
.entitlement-panel-name{
  font-size: 18px;
  color:#ca8317;
  margin: 10px 5px;
  font-weight: 600;
}
.script-run-button,.script-cancel-button{
  padding: 7px 10px;
  font-size: 14px;
  border: 1px solid #7F2C8C;
  margin: 10px 5px;
  font-weight: 600;
  background-color: white;
}
.panel-desc{
  font-size: 14px;
  margin: 10px 5px;
}
.panel-textarea{
  margin: 10px 5px;
  font-size: 14px;
  font-weight: 400;
}
.cell100{
  font-weight: 500;
  font-size: 17px;
  color: #fff;
  line-height: 1.4;
  background-color: #7f2c8ca8;
  padding: 15px;
 
}
.scripts-row-data td{
  font-size: 15px;
  font-weight: 400;
  background-color:white; 
  color: black;
}
.script-button{
  background-color: #7f2c8ca8;
  color: white;
  padding: 8px;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}
/* .ms-Callout {
    width: 45%;
}
.ms-ContextualMenu-Callout{
  width: 70px;
} */