.heading {
  padding: 20px 20px 30px 20px;
  font-size: 25px;
  font-weight: 700;
  border-bottom: 2px solid #ca8317;
  font-family: Candara;
}
button:focus {
  outline: none;
}
.ms-Pivot-text {
  font-size: 14px;
}
.operations-search-box {
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: white;
  margin: 0px;
}
.operations-search-box input {
  width: 30%;
  padding: 10px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: 0.2px solid #ca8317;
  margin-left: 20px;
  margin-right: 10px;
  float: right;
  border-radius: 20px;
  outline: none;
}
.operations-search-box button {
  margin-left: 5px;
  width: 80px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  background-color: #ca8317;
  color: white;
  border: none;
  float: right;
  border-radius: 20px;
  outline: none;
}
.ms-DocumentCardTitle {
  display: inline-block;
}
.ms-DocumentCard {
  margin: 0px;
}
.ms-Panel-main {
  width: 40%;
}
.workspace-contract-panel-button {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: black;
  font-weight: 600;
  background-color: white;
  outline: none;
  border: 0.8px solid #ca8317;
  border-radius: 4px;
  margin-right: 20px;
}
.customer-panel-button {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: black;
  font-weight: 600;
  background-color: white;
  outline: none;
  border: 0.8px solid #ca8317;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.Collapsible__trigger {
  font-size: 14px;
}
.ms-StackItem {
  align-self: unset;
  padding: 5px 0px;
}
.access-toggle {
  display: inline-flex;
  position: relative;
  bottom: 15px;
  left: 15px;
}
.deleted-workspace-img {
  display: inline-flex;
  position: relative;
  bottom: 18px;
  left: 15px;
}
/* .ms-Pivot{
  padding: 10px 20px;
} */
.ms-Label {
  padding: none;
  margin-right: 5px;
  display: contents;
}
/* .ms-Toggle-innerContainer{
  margin-left: 5px;
} */
.workspace-header {
  background-color: #6e7b7a2e;
  padding: 3px 5px;
  font-weight: 700;
  margin-bottom: 10px;
  cursor: pointer;
}
.workspace-header:hover {
  background-color: #ca831785;
  padding: 3px 5px;
  font-weight: 700;
  margin-bottom: 10px;
  cursor: pointer;
}
/* .show-roles-packages{
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-radius: 5px;
  width: fit-content;
  display: inline-flex;
  margin: 5px;
  padding: 2px 5px;
} */
.user-title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}
.customer-contract-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}
.entitlement-cards {
  margin: 15px 15px 0px 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 100px;
  /* border: 1px solid blue; */
}
.show-roles-packages {
  /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
  border: 1px solid #eee8e8;
  border-radius: 5px;
  width: fit-content;
  display: inline-flex;
  margin: 5px 10px;
  padding: 3px 10px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
}
.user-card-roles {
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  max-width: 100%;
  height: auto;
  margin: 10px 10px;
}
.dataTables_wrapper {
  padding: 10px 20px;
}
.showRolesBox {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  max-width: 100%;
  height: auto;
  margin: 10px 25px;
}
.customer-modal {
  border: 1px solid black;
  padding: 20px;
  margin: 0px 20px;
}
.ms-TextField-field {
  min-width: 300px;
  font-size: 14px;
}
.sub-card-roles {
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  padding: 10px 20px;
  max-width: 100%;
  height: auto;
  margin: 10px 10px;
}
.elx-searchbox {
  padding-right: 15px;
}
.elx-searchbox-icon {
  margin: 0px 20px;
  font-size: 16px;
}
.container-heading {
  font-size: 25px;
}
.assessment-container {
  padding: 20px;
}
input.assessment-checkbox {
  width: 15px;
  height: 15px;
  margin: 0px 10px;
}
input.assessment-checkbox:hover {
  width: 15px;
  height: 15px;
  margin: 0px 10px;
  cursor: pointer;
}
.assessment-label {
  font-size: 15px;
}
.assessment-wiki-link {
  font-size: 15px;
  font-style: italic;
  margin-bottom: 15px;
}
.entitlements-collapse {
  margin: 30px;
}
